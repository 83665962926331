@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes pulse {
  0%,
  100% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.1);
    opacity: 0.7;
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999; /* Ensure it is above other content */
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
}

.logo_container {
  width: 10rem;
  height: 10rem;
  border-radius: 5rem;
  box-shadow:
    rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;

  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  background-color: #fff;
  animation: pulse 2s infinite;
}

.image {
  width: 5rem;
  object-fit: contain;
}

.loading_icon {
  position: absolute;
  font-size: 10.5rem;
  color: #69c0ff;
  z-index: -1;
  animation: rotate 2s linear infinite;
}

.text {
  margin-top: 1.6rem;
  font-size: 1.2rem;
  color: #595959;
}
