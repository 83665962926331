.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  overflow-y: scroll;
}

.main {
  width: 100%;
  height: calc(100% - 14.6rem);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;

  /* padding: 2rem; */
}

.left {
  width: 32rem;
  height: 100%;
}

.center {
  width: calc(100% - 64rem);
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.right {
  width: 32rem;
  height: 100%;
}

.empty_container {
  width: calc(100% - 32rem);
  height: 100%;
}
