.container {
  width: 100%;
  height: 100%;
}

.tab_content {
  width: 100%;
  height: calc(100vh - 9.4rem);
  background-color: #f4f4f4;
}
