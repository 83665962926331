.container {
  width: 2.8rem;
  height: 2.8rem;
  background-color: #ff7f0f;
  border-radius: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 1.1rem;
  position: relative;
  flex-shrink: 0;
}

.container:hover {
  cursor: pointer;
}

.extension_container {
  width: 20rem;
  background-color: #fff;
  position: absolute;
  top: 3rem;
  right: -0.8rem;
  border-radius: 0.8rem;
  border: 1px solid #d2d2d7;

  display: flex;
  flex-direction: column;
  flex-direction: column;
  overflow: hidden;
  z-index: 10;
  flex-shrink: 0;
}

.extension_header {
  width: 100%;
  height: 5rem;
  border-bottom: 1px solid #f0f0f0;
  display: flex;
  align-items: center;
  gap: 2rem;
  padding: 0 1rem;
}

.icon_1 {
  color: rgba(0, 0, 0, 0.65);
  font-size: 1.8rem;
}

.icon_2 {
  color: #06c;
  font-size: 1.8rem;
}

.text {
  color: rgba(0, 0, 0, 0.65);
  font-size: 1.5rem;
  line-height: 2.3rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text_2 {
  color: #06c;
  font-size: 1.3rem;
  line-height: 2.1rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.extension_main {
  width: 100%;
  height: calc(100% - 5rem);
  display: flex;
  flex-direction: column;
}

.extension_item {
  width: 100%;
  padding: 0.8rem 1rem;
  display: flex;
  align-items: center;
  gap: 2rem;
  border-bottom: 1px solid #f0f0f0;
}

.extension_item:last-child {
  border: none;
}

.extension_item:hover {
  cursor: pointer;
  background-color: #f8f8f8;
}
