.container {
  width: 0.7rem;
  height: 0.7rem;
  border-radius: 0.35rem;
}

.text {
  font-size: 1.3rem;
  line-height: 2.1rem;
  color: rgba(0, 0, 0, 0.65);

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.wrapper {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
