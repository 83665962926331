.container {
  width: 100%;
  height: 4.8rem;
  background-color: #373737;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1.6rem;
}

.logo {
  width: 2.5rem;
}

.menu_container,
.wrapper {
  height: 100%;
  display: flex;
  align-items: center;
  gap: 1rem;
  flex: 1;
  overflow-y: hidden;
  position: relative;
}

.wrapper {
  width: 100%;
  margin: 0 1.4rem;
  overflow-x: hidden;
}

.menu_container {
  width: calc(100% - 18rem);
  margin: 0 2rem;
  overflow-x: auto;
}

.menu_item_container {
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 1rem;
}

.text_menu {
  color: #fff;
  font-size: 1.2rem;
  width: max-content;
}

.text_menu:hover {
  cursor: pointer;
  color: #f5f5f7;
}

.menu_scroll_button_left,
.menu_scroll_button_right {
  display: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 50;
  border: none;
  padding: 0;
  color: #fff;
  opacity: 0.5;
}

.menu_scroll_button_left {
  left: -0.5rem;
}

.menu_scroll_button_right {
  right: -0.5rem;
}

.wrapper:hover .menu_scroll_button_left,
.wrapper:hover .menu_scroll_button_right,
.menu_container:hover .menu_scroll_button_left,
.menu_container:hover .menu_scroll_button_right,
.menu_scroll_button_left:hover,
.menu_scroll_button_right:hover {
  opacity: 1;
}

@media screen and (max-width: 768px) {
  .menu_scroll_button_left,
  .menu_scroll_button_right {
    display: block;
  }
}
