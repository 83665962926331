.checkbox_container {
  max-width: 200px;
  border-radius: 2px;
  background-color: #fff;
  z-index: 1000;
  box-shadow:
    0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 6px 16px 0 rgba(0, 0, 0, 0.08),
    0 9px 28px 8px rgba(0, 0, 0, 0.05);
}

.checkbox_row {
  max-height: 256px;
  overflow-y: auto;
  overflow-anchor: none;
  padding: 4px 0px 4px 0px;
  position: relative;
}

.checkbox_row::-webkit-scrollbar-track {
  background-color: none;
}

.checkbox_row::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: #f2f4f7;
  border-radius: 8px;
}

.checkbox_row::-webkit-scrollbar-thumb {
  background: #8a8a8a;
  border-radius: 8px;
}

.checkbox_col {
  padding: 4px 12px;
}

.checkbox_col:hover {
  background-color: #f5f5f5;
}

.checkbox_col:active {
  background-color: #e6f7ff;
}

.checkbox_text {
  font-size: 1.3rem;
  line-height: 2.1rem;
  color: rgba(0, 0, 0, 0.65);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.button_filter {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f5f5f5;
  padding: 8px 12px;
}
